import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import Layout from 'components/layout';
import HeroStandardPage from 'components/hero/HeroStandardPage';
import HeroSecondaryPage from 'components/hero/HeroSecondaryPage';
import Spacer from 'components/spacer';
import {
  ImageLeftTextRight,
  FullWidthTextImage,
  ExternalLinksGrid,
  ThreeColumn,
  TwoColumn,
  Locations,
  StackedImageLinks,
  ImageSlider,
  ImageCard,
  VideoBlock,
  DepartmentLiveJobs,
  FAQ,
  ImageLeftContentRight,
  QuartersGrid,
  TextLeftImageRight,
  TitleCaptionText,
  SliderSwiper,
} from 'components/slices';
import { withPreview } from 'gatsby-source-prismic';

function Page(props) {
  const { data } = props;
  const doc = data.prismicStandardPage;
  console.log(doc);
  if (!doc) return null;
  return (
    <Layout headerOpacity="0.9">
      <Box component="div">
        {doc.uid !== 'working-at-ovo' ? (
          <HeroStandardPage
            heroTitle={doc.data.banner_title.text}
            heroImage={doc.data.banner_image.url}
            heroContent={
              doc.data.banner_content ? doc.data.banner_content.raw : ''
            }
          />
        ) : (
          <HeroSecondaryPage
            heroTitle={doc.data.banner_title.text}
            heroImage={doc.data.banner_image.url}
            heroContent={
              doc.data.banner_content ? doc.data.banner_content.raw : ''
            }
          />
        )}
      </Box>
      {doc.data.body && (
        <React.Fragment>
          {doc.data.body.map((item, i) => {
            switch (item.slice_type) {
              case 'image_left_text_right':
                return (
                  <Spacer key={i}>
                    <ImageLeftTextRight data={item.primary} />
                  </Spacer>
                );
              case 'full_width_text_image':
                return (
                  <Spacer key={i}>
                    <FullWidthTextImage data={item.primary} />
                  </Spacer>
                );
              case 'external_links_grid':
                return (
                  <Spacer key={i}>
                    <ExternalLinksGrid
                      data={item.primary}
                      fields={item.items}
                    />
                  </Spacer>
                );
              case 'three_column':
                return (
                  <Spacer key={i}>
                    <ThreeColumn data={item.primary} fields={item.items} />
                  </Spacer>
                );
              case 'two_column':
                return (
                  <Spacer key={i}>
                    <TwoColumn data={item.primary} fields={item.items} />
                  </Spacer>
                );
              case 'locations':
                return (
                  <Spacer key={i}>
                    <Locations data={item.primary} fields={item.items} />
                  </Spacer>
                );
              case 'stacked_image_links':
                return (
                  <Spacer key={i}>
                    <StackedImageLinks
                      data={item.primary}
                      fields={item.items}
                    />
                  </Spacer>
                );
              case 'image_slider':
                return (
                  <Spacer key={i}>
                    <ImageSlider data={item.primary} fields={item.items} />
                  </Spacer>
                );
              case 'image_card':
                return (
                  <Spacer key={i}>
                    <ImageCard data={item.primary} />
                  </Spacer>
                );
              case 'video_block':
                return (
                  <Spacer key={i}>
                    <VideoBlock data={item.primary} />
                  </Spacer>
                );
              case 'faq':
                return (
                  <Spacer key={i}>
                    <FAQ data={item.primary} fields={item.items} />
                  </Spacer>
                );
              case 'image_left_with_content_right':
                return (
                  <Spacer key={i}>
                    <ImageLeftContentRight data={item.primary} />
                  </Spacer>
                );
              case 'quarters_grid':
                return (
                  <Spacer key={i}>
                    <QuartersGrid data={item.primary} fields={item.items} />
                  </Spacer>
                );
              case 'text_left_image_right':
                return (
                  <Spacer key={i}>
                    <TextLeftImageRight data={item.primary} />
                  </Spacer>
                );
              case 'title__caption__text':
                return (
                  <Spacer key={i}>
                    <TitleCaptionText data={item.primary} />
                  </Spacer>
                );
              case 'swiper':
                return (
                  <div key={i}>
                    <SliderSwiper data={item.items} />
                  </div>
                );
              default:
                return null;
            }
          })}
        </React.Fragment>
      )}
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withPreview(Page);

export const query = graphql`
  query StandardPageQuery($uid: String!) {
    prismicStandardPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        banner_title {
          text
        }
        banner_image {
          url
          alt
        }
        banner_content {
          text
          raw
        }
        body {
          ... on PrismicStandardPageBodyImageSlider {
            slice_type
            slice_label
            primary {
              title {
                text
              }
            }
            items {
              image {
                url
                alt
              }
            }
          }
          ... on PrismicStandardPageBodyExternalLinksGrid {
            id
            slice_type
            slice_label
            primary {
              title {
                text
              }
              link_title {
                text
              }
              link {
                url
              }
              caption {
                text
              }
            }
            items {
              title {
                text
              }
              link {
                url
              }
              image {
                url
                alt
              }
              body {
                text
              }
            }
          }
          ... on PrismicStandardPageBodyFaq {
            slice_type
            slice_label
            primary {
              title {
                text
              }
            }
            items {
              question {
                text
              }
              answer {
                text
              }
            }
          }
          ... on PrismicStandardPageBodyFullWidthTextImage {
            slice_type
            slice_label
            primary {
              body {
                text
              }
              caption {
                text
              }
              image {
                url
                alt
              }
              title {
                text
              }
            }
          }
          ... on PrismicStandardPageBodyImageCard {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              image {
                alt
                url
              }
              body {
                text
              }
            }
          }
          ... on PrismicStandardPageBodyImageLeftTextRight {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              image {
                url
                alt
              }
              caption {
                text
              }
              body {
                text
                raw
              }
            }
          }
          ... on PrismicStandardPageBodyImageSlider1 {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              caption {
                text
              }
            }
            items {
              image {
                url
                alt
              }
            }
          }
          ... on PrismicStandardPageBodyLargeImageBlock {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              show_logo_text
              show_logo_ident
              link_title {
                text
              }
              link {
                url
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicStandardPageBodyLocations {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              caption {
                text
              }
            }
            items {
              address {
                text
              }
              image {
                url
                alt
              }
              location {
                longitude
                latitude
              }
              title {
                text
              }
            }
          }
          ... on PrismicStandardPageBodyStackedImageLinks {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              caption {
                text
              }
            }
            items {
              link {
                url
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicStandardPageBodyTextLeftImageRight {
            slice_type
            primary {
              text {
                html
                raw
                text
              }
              image {
                alt
                url
              }
            }
          }
          ... on PrismicStandardPageBodyThreeColumn {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              caption {
                text
              }
            }
            items {
              body {
                text
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicStandardPageBodyTitleCaptionText {
            slice_type
            slice_label
            primary {
              body1 {
                html
                raw
                text
              }
              caption {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicStandardPageBodyTwoColumn {
            id
            slice_type
            slice_label
            primary {
              title {
                text
              }
              caption {
                text
              }
            }
            items {
              title {
                text
              }
              image {
                url
                alt
              }
              body {
                text
                raw
              }
            }
          }
          ... on PrismicStandardPageBodyWidescreenImageBlock {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              image {
                alt
                url
              }
            }
            items {
              link {
                url
              }
              link_title {
                text
              }
            }
          }
          ... on PrismicStandardPageBodyVideoBlock {
            slice_type
            slice_label
            primary {
              video_url {
                text
                raw
                html
              }
              title {
                text
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicStandardPageBodyLatestJobsBlock {
            id
            slice_type
            slice_label
            primary {
              title {
                text
              }
              link_text {
                text
              }
            }
          }
          ... on PrismicStandardPageBodyImageLeftWithContentRight {
            slice_type
            primary {
              image {
                url
                alt
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicStandardPageBodyQuartersGrid {
            slice_type
            primary {
              block_title {
                html
                raw
                text
              }
              caption {
                html
                raw
                text
              }
            }
            items {
              content {
                html
                raw
                text
              }
              expanded_content {
                html
                raw
                text
              }
              image {
                alt
                url
              }
            }
          }
          ... on PrismicStandardPageBodySwiper {
            slice_type
            items {
              image {
                alt
                url
              }
            }
          }
          ... on PrismicStandardPageBodySwiper {
            slice_type
            items {
              image {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`;
